import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import logowritten from '../assets/images/eventle_transparan.png'; // Check path
import logo from '../assets/images/eventle_logo_transparan.png'; // Check path

const Header = () => {
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const sections = ['home', 'services', 'about', 'contact'];

    const handleScroll = () => {
      const offsets = sections.map(id => {
        const element = document.getElementById(id);
        return element ? element.offsetTop - 110 : 0; // Adjust for header height
      });

      const scrollPosition = window.scrollY;
      let newActiveTab = activeTab;

      for (let i = 0; i < offsets.length; i++) {
        if (scrollPosition >= offsets[i] && (i === offsets.length - 1 || scrollPosition < offsets[i + 1])) {
          newActiveTab = sections[i];
          break;
        }
      }

      if (newActiveTab !== activeTab) {
        setActiveTab(newActiveTab);
        navigate(`#${newActiveTab}`, { replace: true });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeTab, navigate, location]);

  const handleTabClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="App-header">
      <div className="header-left">
        <a href="#home" onClick={(e) => {
          e.preventDefault();
          handleTabClick('home');
        }}>
          <img src={logowritten} alt="Eventle Logo Written" />
        </a>
      </div>
      <nav>
        <ul className="nav-tabs">
          <li>
            <a
              href="#home"
              className={activeTab === 'home' ? 'active-tab' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick('home');
              }}
            >
              ANASAYFA
            </a>
          </li>
          <li>
            <a
              href="#services"
              className={activeTab === 'services' ? 'active-tab' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick('services');
              }}
            >
              EVENTLE NASIL ÇALIŞIR
            </a>
          </li>
          <li>
            <a
              href="#about"
              className={activeTab === 'about' ? 'active-tab' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick('about');
              }}
            >
              HAKKIMIZDA
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className={activeTab === 'contact' ? 'active-tab' : ''}
              onClick={(e) => {
                e.preventDefault();
                handleTabClick('contact');
              }}
            >
              İLETİŞİM
            </a>
          </li>
        </ul>
      </nav>
      <div className="header-right">
        <a href="#home" onClick={(e) => {
          e.preventDefault();
          handleTabClick('home');
        }}>
          <img src={logo} alt="Eventle Logo" />
        </a>
      </div>
    </header>
  );
};

export default Header;
