import React from 'react';
import '../App.css';
import ana_sayfa from '../assets/images/ana_sayfa.png';
import user_strict from '../assets/images/kullanıcı_düz.png';
import calendar from '../assets/images/takvim.png';
import map from '../assets/images/mapdüz.png';

function HowWork() {
  return (
    <div className="how-work-section">
      <div className="how-work-content">
        <div className="text-column">
          <p><span className="header">BİLETLİ ETKİNLİKLER</span><br />Şehrindeki tüm etkinlikleri, farklı platformlarda aramakla vakit kaybetmeden tek bir uygulama üzerinden görüntüle.</p>
          <p><span className="header">KULLANICI ETKİNLİKLERİ</span><br />Gerçekleştirmek istediğin etkinliği yarat, arkadaş listendekilerle paylaş ve kolayca sana kimin eşlik edeceğini bul.</p>
          <p><span className="header">EVENTLE TAKVİM</span><br />Etkinlik takvimi üzerinden istediğin tarihteki etkinlikleri görüntüleyip, aynı zamanda arkadaşlarının takviminden uygun zamanlarını bulun ve etkinliğinizi kolayca planlayın.</p>
          <p><span className="header">EVENTLE HARİTA</span><br />Aradığın lokasyondaki etkinlikleri harita üzerinden görüntüle ve sana uygun olan etkinlikleri kolayca bul.</p>
        </div>
        <div className="image-column">
          <img src={ana_sayfa} alt="Biletli Etkinlikler" />
          <img src={user_strict} alt="Kullanıcı Etkinlikleri" />
          <img src={calendar} alt="Eventle Takvim" />
          <img src={map} alt="Eventle Harita" />
        </div>
      </div>
    </div>
  );
}

export default HowWork;

