import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import HowWork from './components/HowWorks';
import Header from './constants/Header';
import './App.css';

function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

function MainApp() {
  const location = useLocation();

  // Function to check if the app is installed
  const openAppOrRedirect = (appScheme, fallbackUrl) => {
    const timeout = setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 1500);

    window.location.href = appScheme;

    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        clearTimeout(timeout);
      }
    });
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const currentPath = location.pathname + location.search;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS-specific deep link handling
      openAppOrRedirect(
        `eventlelink://${currentPath}`,
        'https://apps.apple.com/tr/app/eventle/id6474272088?l=tr'
      );
    } else if (/android/i.test(userAgent)) {
      // Android-specific deep link handling
      openAppOrRedirect(
        `intent://eventlelink${currentPath}#Intent;scheme=eventlelink;package=com.eventle.eventle;end`,
        'https://play.google.com/store/apps/details?id=com.eventle.eventle&hl=tr'
      );
    }
  }, [location]);

  return (
    <div className="App">
      <Header />
      <div id="home">
        <Home />
      </div>
      <div id="services">
        <HowWork />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
}

export default App;
