import React from 'react';
import '../App.css';
import aboutVideo from '../assets/videos/about.mp4';

function About() {
  return (
    <div className="about-section">
      <video autoPlay muted loop className="background-video">
        <source src={aboutVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div> {/* Overlay to darken the video */}
      <div className="about-content">
        <h1>HAKKIMIZDA</h1>
        <p>
          <span className="highlight">Eventle</span> olarak, şehirdeki tüm etkinlikleri tek bir platformda toplamak ve herkesin kolayca erişebilmesini sağlamak için yola çıktık. 
          İster bir konser, ister bir sergi, ister bir spor etkinliği olsun, <span className="highlight">Eventle</span> sayesinde şehirdeki tüm etkinliklere tek bir yerden ulaşabilirsiniz. 
          Ayrıca, kendi etkinliklerinizi oluşturarak daha fazla insanla paylaşabilir ve onların katılımını sağlayabilirsiniz.
        </p>
        <h2>Kurucularımız</h2>
        <p>
          <span className="highlight">Eventle</span>, dört tutkulu arkadaşın - Berkay, Alptuğ, Doğukan ve Arda - ortak vizyonu ve emeğiyle hayat buldu. 
          Hepimiz, insanların şehirdeki etkinliklerden en iyi şekilde haberdar olmalarını ve bu etkinliklere katılmalarını kolaylaştırmak için bir araya geldik.
        </p>
        <h2>Ne Sunuyoruz?</h2>
        <ul>
          <li>
            <strong>Etkinlik Keşfi:</strong> Şehrinizdeki tüm etkinlikleri tek bir platformda keşfedin. Kategorilere göre arama yaparak, ilgi alanlarınıza uygun etkinlikleri bulun.
          </li>
          <li>
            <strong>Kendi Etkinliklerinizi Oluşturun:</strong> Kendi etkinliklerinizi kolayca oluşturun, duyurun ve geniş kitlelere ulaşın.
          </li>
          <li>
            <strong>Harita ve Takvim Özellikleri:</strong> Harita üzerinden etkinlik yerlerini kolayca bulun ve takvim üzerinden etkinliklerin tarihlerini takip edin.
          </li>
          <li>
            <strong>Kolay Kullanım:</strong> Kullanıcı dostu arayüzümüz sayesinde aradığınız etkinlikleri hızlıca bulun ve etkinlik oluşturma süreçlerini kolayca tamamlayın.
          </li>
        </ul>
        <p>
          <span className="highlight">Eventle</span> ile şehirdeki hayatı dolu dolu yaşayın. Her gününüzü yeni bir etkinlikle renklendirin!
        </p>
      </div>
    </div>
  );
}

export default About;
