import React from 'react';
import '../App.css';
import appStoreBadge from '../assets/images/app_store.webp'; // App Store image
import googlePlayBadge from '../assets/images/google-play.webp'; // Google Play image
import tiktokIcon from '../assets/images/tiktok.webp'; // TikTok icon
import instagramIcon from '../assets/images/insta.webp'; // Instagram icon


function Contact() {
  return (
    <div className="contact-section">
      <h1>ŞİMDİ İNDİR VE EVENTLE'MEYE BAŞLA</h1>
      <div className="store-links-contact">
        <a href="https://apps.apple.com/tr/app/eventle/id6474272088?l=tr" target="_blank" rel="noopener noreferrer">
          <img src={appStoreBadge} alt="Download on the App Store" className="store-badge-contact" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.eventle.eventle&pcampaignid=web_share&pli=1" target="_blank" rel="noopener noreferrer">
          <img src={googlePlayBadge} alt="Get it on Google Play" className="store-badge-contact" />
        </a>
      </div>
      <div className="contact-info">
        <h2>Adres</h2>
        <p>Beşiktaş/İstanbul</p>
        <h2>İletişim</h2>
        <p><a href="mailto:berkay.bedir@eventle.co">berkay.bedir@eventle.co</a></p>
        <p><a href="mailto:alptug.ilgili@eventle.co">alptug.ilgili@eventle.co</a></p>
        <div className="social-icons">
          <a href="https://www.tiktok.com/@eventle.co" target="_blank" rel="noopener noreferrer">
            <img src={tiktokIcon} alt="TikTok" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/eventle.co/" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
        </div>
      </div>
      <div className="contact-form">
        <h2>Size Ulaşalım</h2>
        <p>Fark yaratmamıza yardımcı olun</p>
        <input type="text" placeholder="Adınız" className="contact-input" />
        <input type="text" placeholder="Soyadınız" className="contact-input" />
        <input type="email" placeholder="E-posta" className="contact-input" />
        <textarea placeholder="Mesajınız" className="contact-textarea"></textarea>
        <button className="submit-button">Gönder</button>
      </div>
      <footer className="footer">
        ©2024 by Eventle.
      </footer>
    </div>
  );
}

export default Contact;
