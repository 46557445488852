import React from 'react';
import '../App.css';
import homeVideo from '../assets/videos/home.mp4';
import home_image from '../assets/images/ana_sayfa.png'; // Check path
import eventcarddetail from '../assets/images/eventcarddetail.png'; // Check path
import appStoreBadge from '../assets/images/app_store.webp'; // Add App Store image
import googlePlayBadge from '../assets/images/google-play.webp'; // Add Google Play image

function Home() {
  return (
    <div className="home-section">
      <video autoPlay muted loop className="background-video">
        <source src={homeVideo} type="video/mp4" />
      </video>
      <div className="section">
        <div className="intertwined-images">
          <img src={home_image} alt="Home" className="home-image" />
          <img src={eventcarddetail} alt="Event Card Detail" className="eventcard-image" />
          <div className="centered-text">
            <h1>Etkinlik Dünyasının Yeni Tanımı</h1>
            <p>Şehrindeki tüm etkinlikleri tek bir, <br /> uygulamadan görüntüleyebileceğin, <br /> aynı zamanda kendi etkinliği, <br /> oluşturarak EventBuddy'ni, <br /> bulabileceğin Eventle sizlerle!</p>
            <div className="store-links">
              <a href="https://apps.apple.com/tr/app/eventle/id6474272088?l=tr" target="_blank" rel="noopener noreferrer">
                <img src={appStoreBadge} alt="Download on the App Store" className="store-badge" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.eventle.eventle&pcampaignid=web_share&pli=1" target="_blank" rel="noopener noreferrer">
                <img src={googlePlayBadge} alt="Get it on Google Play" className="store-badge" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;